<template>

    <div class="about">

        <h1>{{ componentName }}</h1>

        <p>{{ someData }}</p>

        <div>&copy;{{ new Date().getFullYear() }}. All Rights Reserved.</div>

    </div>

</template>


<script>

export default {

    name: 'About',

    data() {
        return {
            componentName: this.$options.name + ' View',
            someData: 'About this interactive...'
        };
    },

    created() {

    },

    mounted() {

    },

    methods: {

    }

};

</script>


<style lang="scss" scoped>

.about {

}

</style>
